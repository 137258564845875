* {
  box-sizing: border-box;
  font-family: 'HelveticaNowDisplay', sans-serif;
}

body {
  margin: 0;
}

.sidebar-title {
  color: #848484;
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 30%;
}

#container {
  width: 100%;
  /*height: 100vh;*/
}

#sidebar {
  float: left;
  height: 100%;
  width: 18%;
  position: relative;
  padding: 2%;
}

#close {
  position: absolute;
  top: 94%;
  left: 57%;
}

#close:hover {
  cursor: pointer;
}

#logo {
  position: absolute;
  right: 10%;
  top: 2%;
}

#content {
  background-color: #f5f9fc;
  /*height: 100vh; */
  float: right;
  width: 82%;
  padding: 0 40px;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  html,
  body,
  #root,
  #container,
  #content {
    min-height: 100% !important;
    height: 100%;
  } /* fix for portrait */
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  /* landscape */
  #sidebar {
    width: 33%;
  }

  #content {
    width: 67%;
  }
}

@media only screen and (min-height: 1000px) {
  #sidebar {
    width: 23%;
  }

  #content {
    width: 77%;
  }
}

@media only screen and (max-width: 768px) {
  #logo {
    display: none;
  }

  .sidebar-title {
    margin-top: 0;
  }

  #sidebar {
    width: 100%;
    height: auto;
  }
  #content {
    width: 100%;
  }

  html,
  body,
  #root,
  #container,
  #content {
    min-height: 100% !important;
    height: 100%;
  } /* fix for portrait */
}

@media (min-width: 770px) {
  #content {
    height: 100vh;
    background-color: #f5f9fc;
  }

  #container {
    height: 100vh;
  }
}
